
import { Component, OnInit, Output, EventEmitter  } from '@angular/core';

//import * as L from 'leaflet';
// import * as L1 from 'leaflet-search';
declare const L: any; 
// import 'leaflet.markercluster';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/images/marker-icon-2x.png';
import 'leaflet/dist/images/marker-icon.png';
import Geocoder from 'leaflet-control-geocoder';
import { ReportService } from 'src/app/shared/services/report.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Item { name: string; }


@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit {
	private map;
	myMarker:any;
	newMarker:any;
@Output() passMapCoords: EventEmitter<string> = new EventEmitter();

	marker;
	allMarkers;


	constructor(private reportService:ReportService) { }
	async ngOnInit() { 
	this.allMarkers = await this.reportService.getMarkers();
	this.refresh()
		
	}

	private initMap(): void {
		this.map = new L.Map('map');
		// create the tile layer with correct attribution
		var osmUrl='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		var osmAttrib='Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
		var osm = new L.TileLayer(osmUrl, {minZoom: 8, maxZoom: 19, attribution: osmAttrib});       

		// set default coordinates
		this.map.setView(new L.latLng(41.662429, 21.759924),8);
		//add layer contributors
		this.map.addLayer(osm);
		//set search place input
		const GeocoderControl = new Geocoder();
		L.Control.geocoder({ 
			position: 'topleft',
			placeholder:'Пребарај тука...',
			defaultMarkGeocode: false,
		}).on('markgeocode', (e) => {
			this.map.setView([e.geocode.center.lat,e.geocode.center.lng],15);
		}).addTo(this.map);


		this.map.on("click", e => {
			if(this.myMarker){
				this.map.removeLayer(this.myMarker); // remove
			} 
			this.myMarker=L.marker([e.latlng.lat, e.latlng.lng], {draggable: true}).addTo(this.map); // add the marker onclick
			this.newMarker=new Object ({lat:e.latlng.lat,lng:e.latlng.lng})
			this.myMarker.on('dragend', function(event) {
				var latlng = event.target.getLatLng();
				this.newMarker={lat:latlng.lat,lng:latlng.lng};
			});
			this.passMapCoords.emit(this.newMarker);

		});
		//add markers on the map
		var markers = L.markerClusterGroup();
		for (var i = 0; i < this.allMarkers.length; i++) {
			var a = this.allMarkers[i]['map'];
			var id=this.allMarkers[i]['id'];
			var title = a.label;
			
			var marker = L.marker(new L.LatLng(a.lat, a.lng), { title: title });
			marker.bindPopup('<a href="statistics/'+id+'">'+title+'</a>');
			markers.addLayer(marker);
		}
		this.map.addLayer(markers);		
		
	}

	refresh(){
		this.initMap()
	}
	ngAfterViewInit(): void {
		//this.initMap(); 
	}

	

	


}
