<div class="container-fluid footer">
	<div class="container">
		<div class="row">
			<div class="col-8 centerItems">
			{{"Оваа веб страна е продуцирана со финансиска поддршка на UN Women. За содржината е одговорен авторот на веб страната и во никој случај не ги рефлектира позициите на Агенцијата на Обединетите Нации за родова еднаквост и зајакнување на жените (UN Women), Обединетите Нации или придружни организации."|translate}}
			</div>
			<div class="col-4" style="text-align: center">
				<img src="assets/img/unwomen.png">
				<img src='{{"assets/img/reactorMk.png"|translate}}' style="margin-top: 40px">
			</div>
		</div>
	</div>
</div>