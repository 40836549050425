<app-frontend-header></app-frontend-header>
<div class="mapHome" style="position:relative;overflow: hidden;">
	<div class="map-container">
		<div class="map-frame">
			<div id="mapHome"></div>
		</div>
	</div>
	<div class="mapButtons_box">
		<div class="in">
			<div class="item" id="filtericon" (mouseover)="showBoxOnHover($event,'filtericon')" (mouseleave)="out()">
				<div class="items_style filter">
				</div>
				<div class="text">{{"филтри"|translate}}</div>
			</div>
			<div class="item"  id="mapicon" (mouseover)="showBoxOnHover($event,'mapicon')" (mouseleave)="out()">
				<div class="items_style add_report">
				</div>
				<div class="text">{{"мапирај"|translate}}</div>
			</div>
			<div class="item" id="charticon" (mouseover)="showBoxOnHover($event,'charticon')" (mouseleave)="out()">
				<div class="items_style chart">
				</div>
				<div class="text">{{"график"|translate}}</div>
			</div>
			<div class="item" id="infoicon" (mouseover)="showBoxOnHover($event,'infoicon')" (mouseleave)="out()">
				<div class="items_style info">
				</div>
				<div class="text">{{"инфо"|translate}}</div>
			</div>
		</div>
	</div>
	<div class="filtericon_{{changeClass}} filtericon" (mouseover)="showBoxIn('filtericon')" (mouseleave)="out()">

		<ul>
			<li class="list-group-item list-group-hover" (click)="getAllCat()">
				{{"Сите категории"|translate}}</li>
				<li class="list-group-item list-group-hover" (click)="filterMarkers('Навредливи коментaри, свиркање')">{{"Навредливи коментaри, свиркање"|translate}}</li>
				<li class="list-group-item list-group-hover" (click)="filterMarkers('Непријатно допирање или покажување гениталии')">{{"Непријатно допирање или покажување гениталии"|translate}}</li>
				<li class="list-group-item list-group-hover" (click)="filterMarkers('Следење, зјапање или намерно попречување пат')">{{"Следење, зјапање или намерно попречување пат"|translate}}</li>
				<li class="list-group-item list-group-hover" (click)="filterMarkers('Закана')">{{"Закана"|translate}}</li>
				<li class="list-group-item list-group-hover" (click)="filterMarkers('Физички напад')">{{"Физички напад"|translate}}</li>
				<li class="list-group-item list-group-hover" (click)="filterMarkers('Сексуално насилство')">{{"Сексуално насилство"|translate}}</li>
			</ul>
		</div>
		<div class="mapicon mapicon_{{changeClass}} " (mouseover)="showBoxIn('mapicon')" (mouseleave)="out()">
			<div style="padding: 1.25rem">
				Доколку сте жртва или сведок на родово базирано насилство на јавен простор, пријавете го и мапирајте го на следниот линк.
				<a href="/add-report" style="text-decoration: underline;">Пријави нов инцидент !</a>
			</div>
		</div>
		<div class="charticon charticon_{{changeClass}}" (mouseover)="showBoxIn('charticon')" (mouseleave)="out()">
			<div style="padding: 5%">
				<app-highcharts></app-highcharts>
			</div>
		</div>
		<div class="infoicon infoicon_{{changeClass}} " (mouseover)="showBoxIn('infoicon')" (mouseleave)="out()">
			<div style="padding: 1.25rem">
			<div style="text-transform: uppercase;"><strong>{{"Како да пријавам?"|translate}}</strong></div><br>
				<div><strong>{{"Со испраќање email:"|translate}}</strong></div>
				<div>prijavinasilstvo@reactor.org.mk</div><br>
				<div><strong>{{"Со праќање на tweet со хаштагот:"|translate}}</strong></div>
				<div>#реагирај</div><br>
				<div><a href="add-report"> <strong>{{"Со пополнување на овој формулар на нашата страница."|translate}}</strong></a></div>
			</div>
		</div>
	</div>
	<app-frontend-footer></app-frontend-footer>