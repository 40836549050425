<app-frontend-header></app-frontend-header>
<div class="container page">
   <div class="row">
   		<div class="col-12 subtext"><a href="/">{{"Почетна"|translate}}</a> / <a href="/statistics">{{"Поднесени пријави"|translate}}</a></div>

      <div class="col-12" >
         <div class="title">{{reportData?.title}}</div>
      </div>
      <div class="col-md-7 col-xs-12">
         <div class="report_row">
            <div>
               <fa-icon [icon]="faClock"></fa-icon>
               {{reportData?.time}}  
               <fa-icon [icon]="faCalendarAlt"></fa-icon>
               {{reportData?.reportDate|date:'mediumDate'}}  
               <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
               {{reportData?.place_location}}
            </div>
         </div>
         <div class="report_array report_row">
            <div  *ngFor="let cat of reportData?.reportCat">
               <span *ngIf="cat.ischecked" class="true">{{cat.name}}</span>
            </div>
         </div>
         <div class="report_row">
            <div class="subtitle">{{'Опис'|translate}}</div>
            <div>{{reportData?.description}}</div>
         </div>
         <div *ngIf="reportData?.youAre||reportData?.happened||reportData?.placeType|| reportData?.reportPolice||reportData?.policeAttitude||policeAnswer" class="subtitle">{{'Повеќе податоци'|translate}}</div>
         <div *ngIf="reportData?.youAre||reportData?.happened||reportData?.placeType|| reportData?.reportPolice||reportData?.policeAttitude||policeAnswer" class="report_row">
            <div *ngIf="reportData?.youAre">
               <span class="q">{{'Вие сте'|translate}}:</span>
               <p class="answ">{{reportData?.youAre}}</p>
            </div>
            <div *ngIf="reportData?.happened">
               <span class="q">{{'Се случува'|translate}}:</span>
               <p class="answ">{{reportData?.happened}}</p>
            </div>
            <div *ngIf="reportData?.placeType">
               <span class="q">{{'Тип на место'|translate}}:</span>
               <p class="answ">{{reportData?.placeType}}</p>
            </div>
            <div *ngIf="reportData?.reportPolice">
               <span class="q">{{'Пријавивте во полиција'|translate}}:</span>
               <p class="answ">{{reportData?.reportPolice}}</p>
            </div>
            <div *ngIf="policeAnswer">
               <span class="q">{{'Каков беше нивнот одговор?'|translate}}:</span>
               <span *ngFor="let police of reportData?.policeAnswer">
                  <span *ngIf="police.ischecked">
                     <p class="answ">{{police.name}}</p>
                  </span>
               </span>
            </div>
            <div *ngIf="reportData?.policeAttitude" >
               <span class="q">{{'Колку сте задоволни од односот на полицијата?'|translate}}:</span>
               <p class="answ">{{reportData?.policeAttitude}}</p>
            </div>
         </div>
         <div>
            <div class="subtitle" *ngIf="reportData?.perpetratorHeight || reportData?.perpetratorWeigh || reportData?.perpetratorHairColor || reportData?.perpetratorFeatures || reportData?.perpetratorAge">{{'Незадолжителни податоци - Опис на сторителот'|translate}}</div>
            <div *ngIf="reportData?.perpetratorAge">
               <span class="q">{{'Приближна возраст'|translate}}:</span>
               <p class="answ">{{reportData?.perpetratorAge}}</p>
            </div>
            <div *ngIf="reportData?.perpetratorHeight">
               <span class="q">{{'Висина'|translate}}:</span>
               <p class="answ">{{reportData?.perpetratorHeight}}</p>
            </div>
            <div *ngIf="reportData?.perpetratorWeight">
               <span class="q">{{'Тежина'|translate}}:</span>
               <p class="answ">{{reportData?.perpetratorWeight}}</p>
            </div>
            
            <div *ngIf="reportData?.perpetratorHairColor">
               <span class="q">{{'Боја на коса'|translate}}:</span>
               <p class="answ">{{reportData?.perpetratorHairColor}}</p>
            </div>
            <div *ngIf="reportData?.perpetratorFeatures">
               <span class="q">{{'Специфични белези (облека, тетоважи, накит итн.)'|translate}}:</span>
               <p class="answ">{{reportData?.perpetratorFeatures}}</p>
            </div>
         </div>
         <div *ngIf="reportData?.image">
            <div style="margin-top:30px"><b>{{'Слика'|translate}}</b></div>
            <img src="{{reportData?.image}}">
         </div>
         <div *ngIf="reportData?.news_link || reportData?.news_link_2" >
            <div class="report_row" style="margin-top: 30px"><b>{{'Надворешни линкови за пријавата'|translate}}</b></div>
            <div *ngIf="reportData?.news_link" >
               <span class="q">{{'Линк од вести'|translate}}:</span>
               {{reportData?.news_link}}
            </div>
            <div *ngIf="reportData?.news_link_2">
               <span class="q">{{'Надворешен видео линк'|translate}}:</span>{{reportData?.news_link_2}}
            </div>
         </div>
      </div>
      <div class="col-md-5 col-xs-12">
         <div class="map-container">
            <div class="map-frame">
               <div id="mapStatisticPost"></div>
            </div>
         </div>
         <div style="margin-top:30px" class="last_five">
            <div class="report_row" style="color: #be365a;"><b>{{'Последни пријави'| translate}}</b></div>
            <div *ngFor="let report of lastFiveReports" class="report_data" style="margin-bottom: 10px">
               <div class="report_static_date">
                  <span style="color:#bb506c;padding:5px">
                     <fa-icon [icon]="faClock"></fa-icon>
                  </span>
                  {{report.date?.toDate() | date}}
               </div>
               <a routerLink="/statistics/{{report?.id}}" >
                  <div class="report_title">
                     <p class="answ five_rep_title">{{report?.title}}</p>
                  </div>
               </a>
               <p class="report_location">
                  <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
                  {{report?.place_location}}
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<app-frontend-footer></app-frontend-footer>