<app-dashboard-header></app-dashboard-header>
<form [formGroup]="freportUpdate" (ngSubmit)="onSubmit(freportUpdate)">
	<div class="container">
		<div class="row">
			<div class="col-12 subtext"><a href="/dashboard">dashboard</a> / {{"Промени пријава"|translate}}</div>
			<div class="col-12 title">{{'Промени пријава'| translate}}</div>
		</div>
		<div class="row">
			<div class="col-6">
				<div class="form-group">
					<label>{{"Наслов на пријавата"|translate}}</label>
					<input type="text" name="title" class="form-control" formControlName="title" >
				</div>
				<div class="form-group">
					<label for="report_desc">{{"Опис на пријавата"|translate}}
					</label>
					<textarea class="form-control" id="description" name="description"
					formControlName="description"></textarea>
				</div>
				<div class="group">
					<label for="blog_date">{{"Датум и време"|translate}}<span class="mandatory">*</span></label>
					<input type="date" class="form-control" formControlName="reportDate" placeholder="YYYY-MM-dd">
					<input type="hidden" name="date" formControlName="date" class="form-control">
					<input type="hidden" name="time" formControlName="time" class="form-control">

				</div>
				<div class="form-group">
					<label >{{"Категории"|translate}}</label>
					<div *ngFor="let cat of freportUpdate.get('reportCat')?.controls;let i = index">
						<input type="checkbox"
						(change)="isCategorySelected($event,cat.value.name)" [checked]="cat.value.ischecked" id="{{cat.value.name+i}}" value="{{cat.value.name}}">
						<label for="{{cat.value.name}}"> {{cat.value.name}}</label>
					</div>
				</div>
				<div class="form-group">
					<label for="report_a_y">{{"Вие сте"|translate}}<span class="mandatory">*</span></label>
					<div *ngFor="let a_y of are_you" >
						<input type="radio" name="youAre" formControlName="youAre" value="{{a_y.name}}" id="{{a_y.name}}">
						<label for="{{a_y.name}}">{{a_y.name | translate}}</label>
					</div>
				</div>
				<div class="form-group">
					<label for="report_a_y">{{"Се случува"|translate}}</label>
					<div *ngFor="let happened of it_happened" >
						<input type="radio"  name="happened" formControlName="happened" 
						id="{{happened.name}}" value={{happened.name}}>
						<label for="{{happened.name}}"> {{happened.name | translate}}</label>
					</div>

				</div>
				<div class="form-group" >
					<label for="placeType">{{'Тип на место'|translate}}</label>
					<span class="mandatory">*</span>
					<br>
					<select class="form-control" name="placeType" formControlName="placeType">
						<option *ngFor="let place of place_type"  value="{{place.name}}">{{place.name|translate}}</option>
					</select>

				</div>
				<div class="group">
					<label for="report_police">{{"Пријавивте ли во полиција?"|translate}}</label><span class="mandatory">*</span>
					<div *ngFor="let pol of report_police" >
						<input type="radio" name="reportPolice" formControlName="reportPolice" 
						value="{{pol.name}}" id="{{pol.id}}_{{pol.name}}">
						<label for="{{pol.id}}_{{pol.name}}">{{pol.name | translate}}</label>
					</div>


				</div>
				<div class="group">
					<label for="police_attitude">{{"Колку сте задоволни од односот на полицијата?"|translate}}</label>
					<span class="mandatory">*</span>
					<div *ngFor="let atti of police_attitude" >
						<input type="radio" name="policeAttitude" formControlName="policeAttitude" value="{{atti.name}}" id="{{atti.id}}_{{atti.name}}">
						<label for="{{atti.id}}_{{atti.name}}">{{atti.name | translate}}</label>
					</div>

				</div>
				<div class="group">
					<label for="police_answer">{{"Каков беше нивнот одговор?"|translate}}</label><span class="mandatory">*</span>
					<div *ngFor="let answer of freportUpdate.get('policeAnswer')?.controls; let i=index">
						<input type="checkbox" name="policeAnswer" value="{{answer.value.name}}" 
						(change)="isPoliceAnswerSelected($event,answer.value.name)" id="_{{answer.value.name}}" [checked]="answer.value.ischecked">
						<label for="_{{answer.value.name}}">{{answer.value.name | translate}}</label>
					</div>

				</div>
			</div>
			<div class="col-6">
				<div class="group">
					<div class="form-check allowReport">
						<input class="form-check-input" type="checkbox" 
						(change)="onCheckPlublished($event.target.checked)" formControlName="published" [checked]="published">
						<label class="form-check-label" for="flexCheckDefault">
							{{"Одобри"|translate}}
						</label>
					</div>				
					<!-- <div class="mymap">
						<app-map (passMapCoords)="getCoords($event)"></app-map>
						<div *ngIf="!mapDataValid && f.submitted" class="invalid-feedback mandatory_line" ><span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div> -->

					<div class="form-group">
						<label>{{"Прецизирај го местото на локацијата"|translate}}</label>
						<span class="mandatory">*</span>
						<div class="subtext">Пример: позади Рамстор, Железничка, Скопје</div>
						<input type="text" name="place_location" formControlName="place_location" class="form-control">

					</div>				
					<div>
						<div>{{"Линк од вести"|translate}}</div>
						<input class="form-control" type="text" name="news_link" formControlName="news_link">
					</div>
					<div>
						<div>{{"Надворешно видео линк"|translate}}</div>
						<input class="form-control" type="text" name="news_link_2" formControlName="news_link_2">
					</div>

					<div>
						<img src="{{downloadURL}}" name="image" width="200"></div>
						<div>
							<div>{{"Прикачи фотографија"|translate}}</div>
							<input class="form-control" type="file"  (change)="uploadImage($event, f.title)" accept=".png,.jpg" >
							<div class="progress">
								<div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent | async) + '%'" [attr.aria-valuenow]="(uploadPercent | async)" aria-valuemin="0" aria-valuemax="100"></div>
							</div>

						</div>
					</div>
					<div class="group">
						<label class="notMandatory">{{"Незадолжителни полиња - Опис на сторителот"|translate}}</label>
						<div class="row pad">
						<div class="col-6">{{"Приближна возраст"|translate}}</div>
						<div class="col-6"><input class="form-control" type="text" name="perpetratorAge" formControlName="perpetratorAge"></div>
					</div>
						<div class="row pad">
							<div class="col-6">{{"Висина"|translate}}</div>
							<div class="col-6"><input class="form-control" type="text" name="perpetratorHeight" formControlName="perpetratorHeight"></div>
						</div>
						<div class="row pad">
							<div class="col-6">{{"Тежина"|translate}}</div>
							<div class="col-6"><input class="form-control" type="text" name="perpetratorWeight" formControlName="perpetratorWeight"></div>
						</div>
						
						<div class="row pad">
							<div class="col-6">{{"Боја на коса"|translate}}</div>
							<div class="col-6"><input class="form-control" type="text"
								name="perpetratorHairColor" formControlName="perpetratorHairColor"></div>
							</div>
							<div class="row pad">
								<div class="col-6">{{"Специфични белези (облека, тетоважи, накит итн.)"|translate}}</div>
								<div class="col-6"><textarea class="form-control" type="textarea" name="perpetratorFeatures" formControlName="perpetratorFeatures"></textarea></div>
							</div>
							<button class="btn btn-primary" type="submit">{{"Потврди"|translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</form>
		<app-frontend-footer></app-frontend-footer>