import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/shared/services/report.service';
declare const L: any; 
import { map } from 'rxjs/operators';
import { faList, faMapMarkedAlt, faFilter, faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-frontend-statistics',
	templateUrl: './frontend-statistics.component.html',
	styleUrls: ['./frontend-statistics.component.scss']
})
export class FrontendStatisticsComponent implements OnInit {
	allMarkers;
	markers;
	map;
	reports;
	markerIcon = L.icon({
		iconAnchor: [10, 41],
		popupAnchor: [2, -40],
		// specify the path here
		iconUrl: "../assets/img/mypin.png",
		shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
	});

	faList = faList;
	faMapMarkedAlt=faMapMarkedAlt;
	faFilter=faFilter;
	faClock=faClock;
	faMapMarkerAlt=faMapMarkerAlt;
	showList:boolean=true;
	showMap='hidden';
	allCat;
	disablePrevButton=true;
	disableNextButton;
	firstReport;
	lastReport;
	mainFirstReport;
	categories:any=[
	{ name:'Навредливи коментaри, свиркање'},
	{ name:'Непријатно допирање или покажување гениталии'},
	{ name:'Следење, зјапање или намерно попречување пат'},
	{name:"Закана"},
	{ name:"Физички напад"},
	{ name:"Сексуално насилство"},
	{ name:"Потврдени пријави"}];
	objectReportLength=[];
	divStyle;
	colorAll;

	constructor(private reportservice:ReportService) { }

	async ngOnInit() { 
		this.getAllReports();
		this.allMarkers = await this.reportservice.getMarkers();
		this.refresh();	


		//ova moze trea da se preraboti
		for (var rep of this.categories) {
			this.allCat=await this.reportservice.filterReportList(rep.name);
			this.objectReportLength.push({name:rep.name,length:this.allCat.length});
		}
		this.categories=this.objectReportLength;
		//do tuka 
	}

	private initMap(): void {
		this.map = new L.Map('mapList');
		// create the tile layer with correct attribution
		var osmUrl='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		var osmAttrib='Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
		var osm = new L.TileLayer(osmUrl, {minZoom:8, maxZoom: 20, attribution: osmAttrib});  
		// set default coordinates
		this.map.setView(new L.latLng(41.662429, 21.759924),8);
		//add layer contributors
		this.map.addLayer(osm);
		//add markers on start
		this.createMarkers();
	}

	refresh(){
		this.initMap()
	}

	createMarkers(){
		this.markers = L.markerClusterGroup();
		for (var i = 0; i < this.allMarkers.length; i++) {
			var a = this.allMarkers[i]['map'];
			var title = a.label;
			var id=this.allMarkers[i]['id'];
			var marker = L.marker(new L.LatLng(a.lat, a.lng), { title: title, icon:this.markerIcon });
			marker.bindPopup('<a href="statistics/'+id+'">'+title+'</a>');
			this.markers.addLayer(marker);
		}
		this.map.addLayer(this.markers);
	}


	async filterMarkers(cat,i){

		this.allMarkers=await this.reportservice.filterReports(cat);
		//clear markers
		this.markers.clearLayers();
		//create markers based on category id
		this.createMarkers();
		this.reports=await this.reportservice.filterReportList(cat);
		this.divStyle=i;
		this.colorAll='';
	}

	async getAllCat(){
		this.allMarkers=await this.reportservice.getMarkers();
		//clear markers
		this.markers.clearLayers();
		//create markers
		this.createMarkers();
		//call reports with all data
		this.getAllReports();
		this.colorAll='hover';
		this.divStyle='';
	}

	
	// async getAllReports(){
	// 	let allReportsListener=this.reportservice.getAllPublichedReports().snapshotChanges().pipe(
	// 		map(actions => actions.map(a => {
	// 			const data = a.payload.doc.data();
	// 			const id = a.payload.doc.id;
	// 			return { id, ...data };
	// 		}))
	// 		).subscribe(data => {
	// 			this.reports = data;
	// 			this.countAll=this.reports.length;
	// 			allReportsListener.unsubscribe();
	// 		});
	// 	}

		showListReport(){
			this.showList=true;
			this.showMap='hidden';
		}

		showMapReport(){
			this.showList=false;
			this.showMap='visible';
			this.map.invalidateSize(true);
		}


		getAllReports(){
		let listener = this.reportservice.getAllPublichedReports().snapshotChanges().pipe(

			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))
			).subscribe(data => {
				this.reports = data;
				this.firstReport=data[0];
				this.mainFirstReport=data[0];
				this.lastReport=data[data.length-1];
				listener.unsubscribe();
			});
		}


		nextReportPage(){
			
			let listener=this.reportservice.nextPublishedReport(this.lastReport).snapshotChanges().pipe(
				map(actions => actions.map(a => {
					const data = a.payload.doc.data();
					const id = a.payload.doc.id;
					return { id, ...data };
				}))
				).subscribe(data => {
					this.disableNextButton = true;
					var removeCount = 0;
					if (data.length == 11) {
						removeCount = 1;
						this.disableNextButton = false;
					}
					var newArrayReport = [];
					for(let i=0;i<data.length-removeCount;i++){
						newArrayReport.push(data[i]);	
					}
					this.reports = newArrayReport;
					this.firstReport=newArrayReport[0];
					this.lastReport=newArrayReport[newArrayReport.length-1];

					this.disablePrevButton=false;
					listener.unsubscribe();
				});

			}

			prevReportPage(){
				let listener=this.reportservice.prevPublishedReport(this.firstReport).snapshotChanges().pipe(
					map(actions => actions.map(a => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					}))
					).subscribe(data => {
						this.reports = data;
						this.firstReport=data[0];
						this.lastReport=data[data.length-1];
						this.disableNextButton=false;
						if(this.mainFirstReport.title===this.firstReport.title){
							this.disablePrevButton=true;
						}
						listener.unsubscribe();
					});
				}




	}
